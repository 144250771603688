import axios from 'axios';

import API_URL from '../constants/apiConstants'

//const API_URL = process.env.PUBLIC_URL;

// API to get products from mock server
export const getProducts = function () {
    return axios.get( API_URL + '/viewAllProduct' )
        .then( function ( response ) {
            return response.data;
        } )
        .catch( function ( error ) {
            // handle error
            console.log( error );
        } );
}

export const saveCustomBusinessCard = (sendData) => {

    console.log(sendData)

    return axios.post( API_URL + '/saveBusinesscard',sendData )
    .then( function ( response ) {
        return response.data;
    } )
    .catch( function ( error ) {
        // handle error
        console.log( error );
    } );



}

export const login = (sendData) => {

    return axios.post( API_URL + '/api/v1/auth/login',sendData)
    
}


export const register = (sendData) => {

    return axios.post( API_URL + '/api/v1/auth/register',sendData)
    
}



export const getAllCategories = () => {
    return axios.post( API_URL + '/api/v1/category/viewAllCategory',{})
    
}


export const getAllColorsForNeon = () => {
    return axios.post( API_URL + '/api/v1/color/viewAllColor',{})
    
}

export const getFontsForNeon = () => {
    return axios.post( API_URL + '/api/v1/font/viewallfonts',{})
    
}

export const getNeonCollection = () => {
    return axios.post( API_URL + '/api/v1/neon/viewlAllNeon',{})
    
}





