import React ,{useState,useEffect} from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Helmet } from 'react-helmet';
import { login,register,setUser,setLogin } from '../../../actions';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import './login.css'

// import Custom Components
import Breadcrumb from '../../common/breadcrumb';

function LoginOne(props) {


    //if already logged in navigate to home

    if(props.isLogin) {

        props.history.push("/");

    }



    let [username,setUserName] = useState('')
    let [password,setPassword] = useState('')

    let [registerEmail,setRegisterEmail] = useState('')
    let [registerPassword,setRegisterPassword] = useState('')


    
    

    const login =() => {

        if(!username || !password ) {
            return;
        }

        props.login({
            username:username,
            password:password
        }).then((res)=>{


            if(res && res.data && res.data.statusCode === 404) {

                let message = res.data.message ? res.data.message :'User not found..'


                toast.error(message)


            }
            

            if(res && res.data && res.data.statusCode === 200) {


                props.setUser({
                    ...res.data.data,
                    ...res.data.token
                })

                props.setLogin(true);




            }


        })
        


    }
    const registerUser =() => {

        if(!registerEmail || !registerPassword ) {
            return;
        }

        props.register({
            username:registerEmail,
            password:registerPassword,
            ip_address:''
        }).then((res)=>{

            console.log(res)

        })
        


    }

    let paddingBottom = {
        paddingBottom: '15px',
        fontSize:'25px'
    
}

    return (
        <div className="main">
            <Helmet>
                <title> Al Amawaj - Welcome </title>
            </Helmet>



            <div style={{backgroundColor:'black'}} className="login-page  pt-2 pb-8  pb-md-12 pt-lg-17 pb-lg-17"  >
                <div className="container">
                    <div className="form-box" style={{backgroundColor:'black'}}>
                        <div className="form-tab" >
                            <Tabs selectedTabClassName="show" defaultIndex={ 0  } >
                                <TabList className="nav  nav-fill" style={paddingBottom}>
                                    <Tab className="nav-item" style={{color:'#fff95b'}}>
                                        <span className="nav-link">Sign In</span>
                                    </Tab>

                                    <Tab className="nav-item" style={{color:'#fff95b'}}>
                                        <span className="nav-link">Register</span>
                                    </Tab>
                                </TabList>

                                <div className="tab-content">
                                    <TabPanel style={ { paddingTop: "2rem" } }>
                                        <div>
                                            <form action="#">
                                                <div className="form-group" >
                                                    <label htmlFor="singin-email-2"  id="form-title" >Username or email address *</label>
                                                    <input  onChange ={(evt)=>{

                                                        let v = evt.target.value;

                                                        setUserName(v)


                                                    }}value = {username} type="text" className="form-control" id="singin-email-2" name="singin-email" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="singin-password-2"  id="form-title">Password *</label>
                                                    <input  onChange ={(evt)=>{

                                                    let v = evt.target.value;

                                                                    setPassword(v)


                                                        }} value = {password} type="password" className="form-control" id="singin-password-2" name="singin-password" required />
                                                </div>

                                                <div className="form-footer">
                                                    <button onClick={login} type="button" className="login-button">
                                                        <span>LOG IN</span>
                                                        <i className="icon-long-arrow-right"></i>
                                                    </button>


                                                    <a href="pages/login/#a" className="forgot-link" id="form-title">Forgot Your Password?</a>
                                                </div>
                                            </form>
                                            {/* <div className="form-choice">
                                                <p className="text-center">or sign in with</p>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <a href="pages/login/#a" className="btn btn-login btn-g">
                                                            <i className="icon-google"></i>
                                                            Login With Google
                                                    </a>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <a href="pages/login/#a" className="btn btn-login btn-f">
                                                            <i className="icon-facebook-f"></i>
                                                            Login With Facebook
                                                    </a>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <form action="#">
                                            <div className="form-group">
                                                <label htmlFor="register-email-2" id="form-title">Your email address *</label>
                                                <input onChange = {(evt)=>{

setRegisterEmail(evt.target.value)
                                                }} value ={registerEmail} type="email" className="form-control" id="register-email-2" name="register-email" required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="register-password-2" id="form-title">Password *</label>
                                                <input onChange = {(evt)=>{

setRegisterPassword(evt.target.value)
                                                }} value ={registerPassword} type="password" className="form-control" id="register-password-2" name="register-password" required />
                                            </div>

                                            <div className="form-footer ">
                                                <div >
                                                <button type="submit" onClick={registerUser} className="login-button" >
                                                    <span >SIGN UP</span>
                                                    <i className="icon-long-arrow-right" ></i>
                                                </button>
                                                </div>

                                                <div className="custom-control custom-checkbox" style={{marginLeft:'15px'}}>
                                                    <input type="checkbox" className="custom-control-input" id="register-policy-2 "  required />
                                                    <label className="custom-control-label" htmlFor="register-policy-2" id="form-title"> I agree to the <a href="pages/login/#a">privacy policy</a> *</label>
                                                </div>
                                            </div>
                                        </form>
                                        {/* <div className="form-choice">
                                            <p className="text-center">or sign in with</p>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <a href="pages/login/#a" className="btn btn-login btn-g">
                                                        <i className="icon-google"></i>
                                                        Login With Google
                                                    </a>
                                                </div>
                                                <div className="col-sm-6">
                                                    <a href="pages/login/#a" className="btn btn-login  btn-f">
                                                        <i className="icon-facebook-f"></i>
                                                        Login With Facebook
                                                    </a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </TabPanel>
                                </div>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



function mapStateToProps( state ) {
    return {
        isLogin:state.user.isLogin
    }
}

export default connect( mapStateToProps, { login,register,setUser,setLogin } )( LoginOne )
