import { ALL_CATEGORIES  } from "../constants/action-types";



const initialState = {

    allcategories:[]
}

function categoriesReducer( state = initialState, action ) {
    switch ( action.type ) {
        case ALL_CATEGORIES:
            return { ...state, allcategories: action.payload }
        default:
            return state
    }
    return state;
}


export default categoriesReducer 