import { IS_LOGIN_TRUE, IS_LOGIN_FALSE, SET_USER,  } from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';


const getIsLoggedin = () => {

    
    if(localStorage.getItem('user')) {

        return true
    } else {
        return false
    }


}

const getUser = () => {

    
    if(localStorage.getItem('user')) {

        return JSON.parse(localStorage.getItem('user'))
    } else {
        return null
    }


}
const initialState = {
    isLogin: getIsLoggedin(),
    user:getUser()
}

function useReducer( state = initialState, action ) {
    switch ( action.type ) {
        case IS_LOGIN_TRUE:
            return { ...state, isLogin: true }
        case IS_LOGIN_FALSE:
            return { ...state, isLogin: false }
        case SET_USER:
            return { ...state, user: action.payload }
        default:
            return state
    }
    return state;
}


export default useReducer 