import { ALL_FONTS_NEON  } from "../constants/action-types";


const initialState = {

    fonts:[]
}

function fontsReducer( state = initialState, action ) {
    switch ( action.type ) {
        case ALL_FONTS_NEON:
            return { ...state, fonts: action.payload }
        default:
            return state
    }
    return state;
}


export default fontsReducer 