import React from 'react';
import { Link } from 'react-router-dom';

function Footer( props ) {
    const { logo = "assets/images/logo.png", container = "container" } = props;

    return (
        <footer style={{backgroundColor:'black'}} className="footer home-page-container">
            <div className="footer-middle ">
                <div className={ container }>
                    <div className="row">
                        <div className="col-sm-6 col-lg-3">
                            <div className="widget widget-about">
                  <img src={process.env.PUBLIC_URL + '/' + logo} className="footer-logo" alt="Footer Logo" width="105" height="25" style={{border:'2px solid #fff95b'}}/>
                                

                                <div className="widget-call">
                                    <i className="icon-phone" style={{color:'#fff95b'}}></i>
                                    <p style={{color:'#fff95b'}}>Got Question? Call us 24/7</p>
                                    <Link to="tel:#" style={{color:'#fff'}}>+974 33272403</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="widget">
                                <h4 className="widget-title" style={{color:'#fff95b'}}>Useful Links</h4>

                                <ul className="widget-list">
                                    <li><Link to={ `${process.env.PUBLIC_URL}/pages/about` }>About US</Link></li>
                                    <li><Link to="#">Our Services</Link></li>
                                    <li><Link to={ `${process.env.PUBLIC_URL}/pages/about` }>Contact us</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="widget"> 
                                <h4 className="widget-title"  style={{color:'#fff95b'}}>Address</h4>
                                <p style={{color:'#fff95b'}}>Al Amwaj Adverting | B Ring Road<br/>P.O BOX NO: 30784 | DOHA QATAR</p>
                                

<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.8644591957786!2d51.53489291501109!3d25.275144583860047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c52f2a2a4059%3A0x98dd9901fb796761!2sAl%20Amwaj%20Advertising!5e0!3m2!1sen!2sin!4v1665772896553!5m2!1sen!2sin" width="290" height="300" style={{border:'0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="widget">
                                <h4 className="widget-title" style={{color:'#fff95b'}}>Our Services</h4>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className={ container }>
                    <p className="footer-copyright" style={{color:'#fff95b'}}>Copyright © { ( new Date() ).getFullYear() } Al Amwaj. All Rights Reserved.</p>
                    {/* <figure className="footer-payments">
                        <img src={ `${process.env.PUBLIC_URL}/assets/images/payments.png` } alt="Payment methods" width="272" height="20" />
                    </figure> */}
                </div>
            </div>
        </footer>
    );
}

export default React.memo( Footer );