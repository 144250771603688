import { combineReducers } from 'redux';

// Import custom components
import productReducer from './products';
import cartReducer from './cart';
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import filterReducer from './filter';
import modalReducer from './modal';
import  useReducer from './user';
import categoriesReducer from './categoriesReducer';
import colorsReducer from './colorsReducer';
import fontsReducer from './fontsReducer';


const rootReducer = combineReducers( {
    data: productReducer,
    cartlist: cartReducer,
    wishlist: wishlistReducer,
    compare: compareReducer,
    filters: filterReducer,
    modal: modalReducer,
    user: useReducer,
    categories:categoriesReducer,
    colors:colorsReducer,
    fonts:fontsReducer
} );

export default rootReducer;