import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './newheader.css';
import { connect } from "react-redux";

import {getAllCategories} from '../../../actions'

function categoryMenu( props ) {
    const { type = 1 } = props;

    const [categories,setCategories] = useState([])


    useEffect(()=>{

        getAllCategories()
    },[])

    
    useEffect(()=>{

        setCategories(props.allcategories)


    },[props])


    const getAllCategories = () => {
        props.getAllCategories()
    }

    return (
        <div className="dropdown category-dropdown" style={{backgroundColor:'#000'}}>
            <Link to={ `${process.env.PUBLIC_URL}/shop/sidebar/list` } className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static" title="Browse Categories" style={{color:'#fff95b'}}>
                <p style={{color :'#fff95b'}}>Browse Categories</p>
                { type === 3 ?
                    <i className="icon-angle-down" style={{color :'#fff95b'}}></i>
                    : ''
                }
            </Link>

            <div className="dropdown-menu">
                <nav className="side-nav">
                    <ul className="menu-vertical sf-arrows ulisgt" >

                        {
                            categories.map((cat,index)=>{
                                return (

                                    <li key ={index}><Link to="#" style={{color:'#ffffff'}} >{cat.categoryName}</Link></li>

                                )

                            })

                        }
                    </ul>
                </nav>
            </div>
        </div>
    );
}

function mapStateToProps( state ) {
    return {

        allcategories:state.categories.allcategories
        
    }
}

export default connect( mapStateToProps, { getAllCategories  } )( categoryMenu )