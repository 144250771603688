import { ALL_COLORS_NEON  } from "../constants/action-types";


const initialState = {

    colors:[]
}

function colorsReducer( state = initialState, action ) {
    switch ( action.type ) {
        case ALL_COLORS_NEON:
            return { ...state, colors: action.payload }
        default:
            return state
    }
    return state;
}


export default colorsReducer 